.list-check-circle-green,
.list-right-arrow,
.list-check-ps,
.list-check-sp {
  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    margin-left: 20px;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      width: 18px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      position: absolute;
      top: 3px;
      left: -24px;
    }
  }
}

.list-check-sp li:before { background-image: url("^assets/images/icons/arrow-right-sp.svg"); }
.list-check-ps li:before { background-image: url("^assets/images/icons/arrow-right-ps.svg"); }
.list-check-circle-green li:before { background-image: url("^assets/images/icons/check-circle-green.svg"); }
.list-right-arrow li:before { background-image: url("^assets/images/icons/right-arrow.svg"); }


.list-columns {
  column-count: 1;
  column-rule: 1px solid lightgrey;
  column-gap: 50px;

  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}
