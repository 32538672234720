.grid-testimoniale {
  column-count: 1;
  column-rule: 1px solid lightgrey;
  column-gap: 50px;

  .avoid-column {
    break-inside: avoid-column;
  }

  @include media-breakpoint-up(md) {
    column-count: 2;
  }

  @include media-breakpoint-up(xl) {
    column-count: 3;
  }
}

.grid-testimoniale.columns-4 {
  @include media-breakpoint-up(md) {
    column-count: 3;
  }

  @include media-breakpoint-up(xl) {
    column-count: 4;
  }
}

.grid-testimoniale.columns-2 {
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}
