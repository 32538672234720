
.mt-10 {margin-top: 10px;}
.mb-10 {margin-bottom: 10px;}

.mt-25 {margin-top: 25px;}
.mb-25 {margin-bottom: 25px;}

.mt-50 {margin-top: 50px;}
.mb-50 {margin-bottom: 50px;}

.mt-100 {margin-top: 100px;}
.mb-100 {margin-bottom: 100px;}

.pt-10 {padding-top: 10px;}

.pt-25 {padding-top: 25px;}
.pb-25 {padding-bottom: 25px;}

.pt-50 {padding-top: 50px;}
.pb-50 {padding-bottom: 50px;}

.pt-75 {padding-top: 75px;}
.pb-75 {padding-bottom: 75px;}

.pt-100 {padding-top: 100px;}
.pb-100 {padding-bottom: 100px;}

.ml-10 {margin-left: 10px;}

.gap-10 {gap: 10px;}
.gap-25 {gap: 25px;}
.gap-50 {gap: 50px;}

.medium {font-weight: 500;}
.strong { font-weight: 700; }

.text-justify {text-align: justify;}
.clickable {cursor: pointer;}

.gray-bg {
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.text-gold {
  color: #F7971E;
  //background: -webkit-linear-gradient(90deg, #fcff9e 0%, #c67700 100%);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}

.text-red-dark { color: #cd3e30; }

.bg-2 {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.container-sm {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.container-lg {
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
}

.grid-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.grid-3col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
}

.grid-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}

.grid-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 50px;
}

.grid-3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 50px;
}

.grid-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.grid-background + .grid-2col {
  z-index: 1;
  position: relative;
}

.col-self-center {
  justify-self: center;
  align-self: center;
}

.col-align-center {
  align-self: center;
}

.img-rounded { border-radius: $border-radius; }

.blockquote {
  font-size: 16px;
  margin-bottom: 25px;
}

.blockquote-footer {
  margin-bottom: inherit;
  margin-top: inherit;
  font-size: 1rem;
}

.marker {
  border-radius: $border-radius;
  background: lighten($actiuni-primary, 20%);
  padding: 0 8px;
  white-space: nowrap;
}

.curly-arrow-right {

  @media (min-width: 1000px) {
    &:before {
      content: '';
      background-image: url("^assets/images/icons/curly-arrow-right.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: -50px;
      left: 40px;
      width: 350px;
      height: 170px;
    }
  }
}

@include media-breakpoint-only(sm) {
  .container {
    max-width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .grid-2col,
  .grid-4col {
    grid-template-columns: 1fr;
    gap: 25px;
  }
}

@include media-breakpoint-down(md) {
  .container-lg,
  .container-sm,
  .container {
    max-width: 90%;
  }
}


