@font-face {
  font-family: "Source Serif Pro";
  src: url("^assets/fonts/source-serif-pro/SourceSerifPro-Light.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("^assets/fonts/source-serif-pro/SourceSerifPro-Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("^assets/fonts/source-serif-pro/SourceSerifPro-Bold.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Rubik";
  src: url("^assets/fonts/rubik/Rubik-Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Rubik";
  src: url("^assets/fonts/rubik/Rubik-Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Rubik";
  src: url("^assets/fonts/rubik/Rubik-Semibold.ttf") format("ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Rubik";
  src: url("^assets/fonts/rubik/Rubik-Italic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("^assets/fonts/inter/Inter-Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("^assets/fonts/inter/Inter-Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("^assets/fonts/inter/Inter-SemiBold.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("^assets/fonts/inter/Inter-Bold.ttf") format("ttf");
  font-weight: 700;
}
