@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,123,0, 0.4);
  }
  50% {
    -webkit-box-shadow: 0 0 0 10px rgba(123,255,0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,123,0, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,123,0, 0.4);
    box-shadow: 0 0 0 0 rgba(255,123,0, 0.4);
  }
  50% {
    -moz-box-shadow: 0 0 0 10px rgba(255,123,0, 0);
    box-shadow: 0 0 0 10px rgba(255,123,0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 123, 0, 0);
    box-shadow: 0 0 0 0 rgba(255,123,0, 0);
  }
}

@keyframes come-in {
  to { transform: translateY(0); }
}

@-webkit-keyframes bounce {
  0% {-webkit-transform: translateY(0); opacity: 0;}
  60% { opacity: 1;}
  100% {-webkit-transform: translateY(60px); opacity: 0}
}

@-moz-keyframes bounce {
  0% {-webkit-transform: translateY(0); opacity: 0;}
  60% { opacity: 1;}
  100% {-webkit-transform: translateY(60px); opacity: 0}
}

@-o-keyframes bounce {
  0% {-webkit-transform: translateY(0); opacity: 0;}
  60% { opacity: 1;}
  100% {-webkit-transform: translateY(60px); opacity: 0}
}
@keyframes bounce {
  0% {-webkit-transform: translateY(0); opacity: 0;}
  60% { opacity: 1;}
  100% {-webkit-transform: translateY(60px); opacity: 0}
}
