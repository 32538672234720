$assetsPath: '../assets/';

$body-bg: #fcfcfc;
$body-color: #484746;

$silver: #707073;
$gold: #ff9d66;
$vip: #8181A2;
$danger: red;

$etf-primary: #25ce9e;
$etf-secondary: #d9a404;

$actiuni-primary: #5dc9e5;
$actiuni-secondary: #d9a404;

$bursa-nivel-3-primary: #e86933;

$imobiliare-nivel-2-primary: #559E43;
$imobiliare-nivel-2-secondary: #ED8F29;

$imobiliare-primary: #F1C071;
$money-smart-primary: #66C7C2;
$money-smart-secondary: #FF9D66;
$money-smart-purple: #53537C;

$income-accelerator-primary: #35759C;
$income-accelerator-secondary: #4AA59E;
$cpip-primary: #327DE2;
$de-neinvins-primary: #F05A28;

$p12: #00848F;

$moneydays-primary: #113D72;

$primul-portofoliu-primary: #44BCB0;

$font-size-base: 1rem;
$headings-font-weight: 600;
$font-family-sans-serif: 'Inter', sans-serif;
$headings-font-family: 'Inter', sans-serif;
$font-baloo: 'Baloo Da 2', cursive;

$bg-radius: 40px;
$border-radius: 8px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
