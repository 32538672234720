@mixin btn() {
  padding: 10px 30px 8px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;

  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;

  text-decoration: none;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  &.btn-sm {
    padding: 6px 15px 3px;
  }
}

.btn-grad-gold {
  @include btn;
  background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
}

.btn-grad-green {
  @include btn;
  background-image: linear-gradient(to right, #1D976C 0%, #93F9B9  51%, #1D976C  100%);
}

.btn-grad-blue {
  @include btn;
  background-image: linear-gradient(to right, #337de1 0%, #33b4ec 51%, #337de1 100%);
}

.btn-grad-blue-dark {
  @include btn;
  background-image: linear-gradient(to right, #022A59 0%, #00506D 51%, #022A59 100%);
}

.btn-outline {
  border: 1px solid #222;
  text-transform: uppercase;
  padding: 7px 25px 5px;
  font-size: 17px;
}

.btn-outline-green {
  border-color: #00848F;
  color: #00848F;
  transition: all 250ms ease;

  &:hover {
    background-color: #00848F;
    color: #fff;
  }
}

.btn-outline-gold {
  border-color: #C89600;
  color: #C89600;
  transition: all 250ms ease;

  &:hover {
    background-color: #C89600;
    color: #fff;
  }
}
