.bg-header {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: $bg-radius;
}

header .container {
  .logo {
    max-width: 400px;
  }
}

@media (max-width: 390px) {
  .logo {
    width: 100%;
  }
}

@media (max-width: 769px) {
  header {
    padding: 25px 0;
    .grid-2col {
      grid-template-columns: 1fr;
    }
    .grid-background {
      .bg-header {
        opacity: 10%;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 770px) {
  header .container {
    min-height: 450px;
  }
}
